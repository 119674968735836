<template>

  <div class="services-page">
    <Spinner />
    <div class="search">
      <div>
        <svg
          width="32"
          height="38"
          viewBox="0 0 32 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 10.9987V14.9987C24 15.3523 23.8595 15.6915 23.6095 15.9415C23.3594 16.1916 23.0203 16.332 22.6667 16.332H21.3333V10.9987H10.6667V16.332H9.33333C8.97971 16.332 8.64057 16.1916 8.39052 15.9415C8.14048 15.6915 8 15.3523 8 14.9987V10.9987H2.66667V34.9987H29.3333V10.9987H24ZM8 8.33203C8 6.2103 8.84285 4.17547 10.3431 2.67518C11.8434 1.17489 13.8783 0.332031 16 0.332031C18.1217 0.332031 20.1566 1.17489 21.6569 2.67518C23.1571 4.17547 24 6.2103 24 8.33203H30.6667C31.0203 8.33203 31.3594 8.47251 31.6095 8.72255C31.8595 8.9726 32 9.31174 32 9.66536V36.332C32 36.6856 31.8595 37.0248 31.6095 37.2748C31.3594 37.5249 31.0203 37.6654 30.6667 37.6654H1.33333C0.979711 37.6654 0.640573 37.5249 0.390525 37.2748C0.140476 37.0248 0 36.6856 0 36.332V9.66536C0 9.31174 0.140476 8.9726 0.390525 8.72255C0.640573 8.47251 0.979711 8.33203 1.33333 8.33203H8ZM10.6667 8.33203H21.3333C21.3333 6.91754 20.7714 5.56099 19.7712 4.56079C18.771 3.5606 17.4145 2.9987 16 2.9987C14.5855 2.9987 13.229 3.5606 12.2288 4.56079C11.2286 5.56099 10.6667 6.91754 10.6667 8.33203Z"
            fill="white"
          />
          <path
            d="M2.66667 26.9987H29.3333V29.6654H2.66667V26.9987Z"
            fill="white"
          />
        </svg>
        <h2 class="title">
          {{ $t("ServicesSearchSection") }}
        </h2>
      </div>
      <div class="search-box">
        <input
          type="text"
          name="search"
          v-model="search"
          :placeholder="
            this.$i18n.locale == 'ar'
              ? `ابحث باسم الخدمة...`
              : `Search by service name...`
          "
        />
        <button @click="SearchByTitle" class="btn-main">
          {{ this.$i18n.locale == "ar" ? `البحث` : `Search` }}
        </button>
      </div>
    </div>

    <ul class="nav nav-pills" id="pills-tab3" role="tablist">
      <swiper
        class="swiper d-block"
        :speed="1000"
        :breakpoints="swiperOptions.breakpoints"
      >
        <swiper-slide
          v-for="item in MainCateg"
          :key="item.id"
          role="presentation"
        >
          <li class="nav-item active" role="presentation">
            <button
              @click="mainCatgId(item.id)"
              :data-index="'Packages' + item.id"
              :class="['nav-link py-3', { active: item.id == activeSlideId2 }]"
              :id="'v-pills-Packages' + item.id + '-tab'"
              data-bs-toggle="pill"
              :data-bs-target="'#v-pills-Packages' + item.id"
              type="button"
              role="tab"
              :aria-controls="'v-pills-Packages' + item.id"
              aria-selected="true"
            >
              <span class="fs-sm-4">{{ item.name }}</span>
            </button>
          </li>
        </swiper-slide>
      </swiper>
    </ul>

    <div class="tab-content" style="margin-top: -25px" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="v-pills-Packages1"
        role="tabpanel"
        aria-labelledby="v-pills-Packages1-tab"
        v-if="SubCateg.length > 0 || activeSlideId2 == 0"
      >
        <div class="services-categories">
          <swiper
            class="swiper"
            :space-between="20"
            :speed="1000"
            :breakpoints="swiperOptions2.breakpoints"
            navigation
          >
            <swiper-slide
              @click="SubCategId(item.id)"
              :class="['item', { active: activeSlideId === item.id }]"
              v-for="item in SubCateg"
              :key="item.id"
            >
              <h3 class="name">
                {{ item.name }}
              </h3>
            </swiper-slide>
          </swiper>
        </div>
        <div class="services-list row mt-3">
          <div
            class="col-lg-3 col-md-4"
            v-for="item in ServicesCard"
            :key="item.id"
          >
            <div class="item">
              <div class="check-mark" v-if="item.checkyes">
                <svg
                  width="82px"
                  height="82px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#198754"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0" />
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M4 12.6111L8.92308 17.5L20 6.5"
                      stroke="#198754"
                      stroke-width="0.984"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <div>
                <img
                  :src="item.image"
                  alt="Item Image"
                  width="50"
                  height="50"
                />
              </div>
              <h2 class="title">{{ item.title }}</h2>
              <p>{{ truncateText(item.description, 120) }}</p>
              <router-link
                class="btn-main"
                style="background-color: #0349a8"
                :to="'/services/details/' + item.id"
              >
                {{ $t("Show Service") }}
              </router-link>
            </div>
          </div>

          <div class="pagination" style="justify-content: center;">

<nav aria-label="Page navigation" class="navigation">
 <ul class="pagination justify-content-center">
   <li class="page-item" :class="{ disabled: currentPage === 1 }">
     <a
       class="page-link"
       href="#"
       @click.prevent="changePage(currentPage - 1)"
       >{{ $t("Previous") }}</a
     >
   </li>
   <li
     class="page-item"
     v-for="page in totalPages"
     :key="page"
     :class="{ active: currentPage === page }"
   >
     <a
       class="page-link"
       href="#"
       @click.prevent="changePage(page)"
       >{{ page }}</a
     >
   </li>
   <li
     class="page-item"
     :class="{ disabled: currentPage === pagesCount }"
   >
     <a
       class="page-link"
       href="#"
       @click.prevent="changePage(currentPage + 1)"
       >{{ $t("Next") }}</a
     >
   </li>
 </ul>
</nav>
 </div>
        </div>

      </div>



      <NoDataToShow v-else style="margin-top: 50px; margin-bottom: 35px" />
    </div>
  </div>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";
import Spinner from "../Global/Spinner.vue";
import axios from "axios";
import { defineAsyncComponent } from "vue";
import Cookie from "cookie-universal";
const cookie = Cookie();
export default {
  components: {
    Spinner,
  },
  data() {
    return {
      currentPage: 1,
      pagesCount: 0,
      param:1,
      included_in_package: false,
      Subscriptions: [],
      packageDetails: {},
      swiper: null,
      search: "",
      category_id: Number,
      swiperOptions: {
        breakpoints: {
          300: {
            // when window width from 300px to 576px
            slidesPerView: 2,
            spaceBetween: 10,
          },
          450: {
            // when window width from 576px to 767px
            slidesPerView: 3,
            spaceBetween: 10,
          },
          700: {
            // when window width from 767px to 991px
            slidesPerView: 3,
            spaceBetween: 10,
          },

          991: {
            // when window width from 991px to 1200px
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1200: {
            // when window width from 991px to 1200px
            slidesPerView: 5,
            spaceBetween: 10,
          },
        },
      },
      swiperOptions2: {
        breakpoints: {
          300: {
            // when window width from 300px to 576px
            slidesPerView: 2,
            spaceBetween: 10,
          },
          576: {
            // when window width from 576px to 767px
            slidesPerView: 2,
            spaceBetween: 50,
          },
          767: {
            // when window width from 767px to 991px
            slidesPerView: 3,
            spaceBetween: 50,
          },

          991: {
            // when window width from 991px to 1200px
            slidesPerView: 4,
            spaceBetween: 50,
          },
          1200: {
            // when window width from 1200px to higher
            slidesPerView: 6,
            spaceBetween: 12,
          },
        },
      },

      MainCateg: [],
      SubCateg: [],
      ServicesCard: [],
      activeSlideId: 1,
      activeSlideId2: 1,
    };
  },

  components: {
    NoDataToShow: defineAsyncComponent(() =>
      import("@/components/Global/NoDataToShow.vue")
    ),
  },
  mounted() {
    if (this.$route.query.q) this.search = this.$route.query.q;
    // this.$store.dispatch("SETLOADER", true);
    this.$store.dispatch("MainCatgsServices").then((r) => {
      this.MainCateg = this.$store.state.MainCategSerives;
      if (this.MainCateg[0].id > 0)
        this.$store
          .dispatch("SubCatgsServices", this.MainCateg[0].id)
          .then((r) => {
            this.SubCateg = this.$store.state.SubCategSerives;
            this.$store
              .dispatch("ServicesCard", {
                id: this.SubCateg[0].id,
                keywords: this.search,
                page:this.currentPage
              })
              .then((r) => {
                this.ServicesCard = this.$store.state.ServicesCard;
                this.totalPages = this.$store.state.pagesCount
                if (cookie.get("CompanyToken")) {
                  this.GetSubscriptions(this.ServicesCard);
                }
              });
          });
      else
        this.$store
          .dispatch("ServicesCard", { id: 0, keywords: this.search,page:this.currentPage })
          .then((r) => {
            this.ServicesCard = this.$store.state.ServicesCard;
            this.totalPages = this.$store.state.pagesCount
            if (cookie.get("CompanyToken")) {
              this.GetSubscriptions(this.ServicesCard);
            }
          });
    });
  },
  methods: {
    GetSubscriptions(items) {
      // this.$store.commit("SET_Loader", true);
      axios
        .get(`/company/dashboard/subscriptions`, {
          headers: {
            Authorization: `Bearer ${cookie.get("CompanyToken")}`,
          },
        })
        .then((res) => {
          this.Subscriptions = res.data.data.Subscription;
          this.Subscriptions.forEach((item) => {
            this.$store
              .dispatch("PackagesDetails", item.package_id)
              .then((res) => {
                this.packageDetails = this.$store.state.PackagesDetails;
                this.packageDetails.services.forEach((serv) => {
                  serv.services.forEach((servid) => {
                    items.forEach((card) => {
                      if (card.id == servid.service_id) {
                        card.checkyes = true;
                      }
                    });
                  });
                });
              });
          });

          // this.$store.commit("SET_Loader", false);
        })
        .catch(function (error) {
          // this.$store.commit("SET_Loader", false);
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    SearchByTitle() {
      var self = this;
      if (this.search != "") {
        this.$store.commit("SET_Spinner", true);
        axios
          .get(
            `/user/services?title=${this.search}` +
              (this.activeSlideId > 0
                ? `&category_id=${this.activeSlideId}`
                : ``),
            {
              headers: {
                Authorization: `Bearer ${cookie.get("CompanyToken")}`,
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              let services = res.data.data["Service"];
              this.ServicesCard = services;
              this.$store.commit("SET_Spinner", false);
            }
          })
          .catch(function (error) {
            console.log("Error: ", error);
            self.$store.commit("SET_Spinner", false);
          });
      }
    },
    truncateText(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    mainCatgId(id) {
      if (id == 0) {
        this.activeSlideId2 = 0;
        this.activeSlideId = 0;
        this.SubCateg = [];
        this.$store
          .dispatch("ServicesCard", { id: 0, keywords: this.search,page:this.currentPage })
          .then((r) => {
            this.ServicesCard = this.$store.state.ServicesCard;
            this.totalPages = this.$store.state.pagesCount
          });
        return false;
      }
      this.activeSlideId2 = id;
      this.$store.dispatch("SubCatgsServices", id).then((r) => {
        this.SubCateg = this.$store.state.SubCategSerives;
        this.activeSlideId = this.SubCateg[0].id;
        this.$store
          .dispatch("ServicesCard", {
            id: this.SubCateg[0].id,
            keywords: this.search,
            page:this.currentPage
          })
          .then((r) => {
            this.ServicesCard = this.$store.state.ServicesCard;
            this.totalPages = this.$store.state.pagesCount
            if (cookie.get("CompanyToken")) {
              this.GetSubscriptions(this.ServicesCard);
            }
          });
      });
    },

    SubCategId(id) {
      this.activeSlideId = id;
      this.$store
        .dispatch("ServicesCard", { id: id, keywords: this.search,page:this.currentPage })
        .then((r) => {
          this.ServicesCard = this.$store.state.ServicesCard;
          this.totalPages = this.$store.state.pagesCount
          if (cookie.get("CompanyToken")) {
            this.GetSubscriptions(this.ServicesCard);
          }
        });
    },

    changePage(page){
      if (page >= 1) {
        this.$store
        .dispatch("ServicesCard", { id: this.activeSlideId, keywords: this.search,page:page })
        .then((r) => {
          this.ServicesCard = this.$store.state.ServicesCard;
          this.totalPages = this.$store.state.pagesCount
          this.currentPage = page;
          if (cookie.get("CompanyToken")) {
            this.GetSubscriptions(this.ServicesCard);
          }
        });

  }
    }
  },
};
</script>
